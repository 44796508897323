import axios from 'axios';
import store from '@/store';
import router from "@/router";

axios.interceptors.response.use(undefined, async function (error) {
    let config = error.config;
    // If config does not exist, reject
    if(!config) return Promise.reject(error);

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status === 419) {
            //This is an issue with the CSRF token, fetch a new CSRF token and try the request again
            config.__retryCount = config.__retryCount || 0;
            if(config.__retryCount < 1) {
                await axios.get('/sanctum/csrf-cookie')
                config.__retryCount += 1;
                return axios(config)
            }
            else {
                return Promise.reject(error);
            }

        }
        else if(error.response.status === 401 && router.currentRoute.meta.auth)
        {
            //This is an authentication issue - redirect to the login page
            await store.dispatch('auth/reset');
            window.location = '/login';
        }
        else
        {
            return Promise.reject(error);
        }
    } else if (error.request) {
        // The request was made but no response was received - retry the request 3 times each with 500ms delay
        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;
        const retryDelay = 500 * (config.__retryCount + 1); //ms
        const retryMethods = ['GET','PUT','HEAD','OPTIONS','DELETE'];
        // Only retry with configured HttpMethods that are idempotent.
        if ( !error.config.method || retryMethods.indexOf(error.config.method.toUpperCase()) < 0 ) {
            return Promise.reject(error);
        }

        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, retryDelay);
        });

        // Retry the request if there are retries remaining
        if(config.__retryCount < 2) {
            // Increase the retry count
            config.__retryCount += 1;
            // Return the promise in which recalls axios to retry the request
            return backoff.then(function() {
                return axios(config);
            });
        }
        else {
            // Reject with the error
            return Promise.reject(error);
        }

    } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject(error);
    }
});



