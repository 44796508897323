import axios from "axios";
import store from "@/store";

//TODO fix class inheritance - as cannot extend baseAPIrepository more than once in this project - as such copying all methods from baseAPIrepository
export default class CustomersRepository	{

    static resource() { return 'customer' }

    static get() {
        return axios.get(this.prefix() + this.resource()).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static update(payload) {
        return axios.put(this.prefix() + this.resource(), payload).then((response) => {
            store.dispatch('toast/trigger', {text: "The customer was successfully updated", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
            throw error;
        })
    }

    static searchByEmail(payload) {
        return axios.post(this.prefix() + this.resource() + '/search-email', payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static searchByEmailOnboarding(payload) {
        return axios.post('/api/' + this.resource() + '/search-email', payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static updateOnboarding(payload) {
        return axios.put(this.prefix() + this.resource() + '/onboarding', payload).then((response) => {
            store.dispatch('toast/trigger', {text: "The customer was successfully updated", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }




    static prefix(propertyId = null) {
        if(propertyId === null) {
            return '/api/user/';
        }
        else {
            return '/api/user/property/' + propertyId + '/';
        }

    }

    static formatIndexParams(options = null, search = null)    {
        let params = {};
        if(options !== null)
        {
            let sortPrefix = options.sortDesc[0] ? '-' : '';
            let sort = options.sortBy[0] == null ? '' : sortPrefix + options.sortBy[0];
            params = {
                params: {
                    page: options.page,
                    itemsPerPage: options.itemsPerPage ?? null,
                    sort: sort,
                    search: (search === null) ? '' : encodeURIComponent(search),
                }
            };
        }
        return params;
    }

    static index(options = null, search = null) {

        let params = this.formatIndexParams(options, search)

        return axios.get(this.prefix() + this.resource(), params).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }


    static create(payload) {
        return axios.post(this.prefix() + this.resource(), payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }


    static delete(id) {
        return axios.delete(this.prefix()+ this.resource() + `/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static associateProperties(id, properties) {
        return axios.post(this.prefix() + this.resource() + `/associate-properties`, {
            properties: properties
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static catchError(error = null)  {
        let errorMessage ="An error has occurred. Please try again";
        if(error !== null && error.response && error.response.status === 400) {
            errorMessage = error.response.data.message;
        } else if (error !== null && error.response && error.response.status === 422) {
            errorMessage = '';
            for (let errorKey in error.response.data.errors) {
                errorMessage += error.response.data.errors[errorKey].reduce( (errorToShow, currentError) => {
                    return errorToShow + currentError;
                }, '');
            }
        } else if (error !== null && error.response && error.response.status === 429) {
            errorMessage = error.response.data.message.slice(0, -1) + ", please try again later!";
        }

        store.dispatch('toast/trigger', {text: errorMessage, color: "error"}, {root: true})
    }

}
