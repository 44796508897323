<template>
  <v-chip
      small
      label
      :color="stausColour"
      class="white--text"
  >{{ statusText }}
  </v-chip>
</template>

<script>

export default {
  props: {
    status: String,
  },
  computed: {
    stausColour() {
      if(this.status === 'confirmed') return 'green';
      else if(this.status === 'checked-in') return 'primary';
      else if(this.status === 'cancelled') return 'error';
      else if(this.status === 'requested') return 'warning';
      else return 'default';
    },
    statusText()  {
      return this.status.toUpperCase()
    }
  },

}
</script>
