<template>
  <v-snackbar
    :value="active"
    v-on:input="close()"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <v-btn
      dark
      text
      @click="close()"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      text: 'toast/text',
      color: 'toast/color',
      active: 'toast/active',
    }),
  },
  methods: {
      ...mapActions({
        close: 'toast/close',
      }),
    },
    data () {
      return {
        mode: '',
        snackbar: false,
        timeout: 5000,
        x: null,
        y: 'bottom',
      }
    },
  }
</script>
