<template>
    <span>
        <pre>{{ formattedText.content }}</pre>
        <a class="blue--text text-caption" @click="toggleShowMore()" v-if="formattedText.truncated">
            {{ showMore ? 'Show less' : 'Show more' }}
        </a>
    </span>
</template>|

<script>
export default {
    props: {
        text: {
            
        },
        textLength: {
            type: Number,
            default: 100
        }
    },
    computed: {
        formattedText() {
            if(typeof this.text !== 'string') {
                return {
                    truncated: false,
                    content: this.text
                }
            }
            const paragraphs = this.text.split('\n');
            const truncated = paragraphs.length > 3 || this.text.length > this.textLength;
            const content = truncated && !this.showMore
                ? paragraphs.slice(0, 3).reduce((text, paragraph) => text.length < this.textLength ? text + paragraph.substring(0, this.textLength - text.length) + '\n' : text, '')
                : paragraphs.join('\n');
            return {
                truncated,
                content
            };
        }
    },
    data: () => ({
        showMore: false
    }),

    methods: {
        toggleShowMore() {
           this.showMore = !this.showMore
        },
    }
}
</script>

<style>
pre {
  white-space: pre-wrap;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}
</style>