import ReservationsRepository from "@/api/ReservationsRepository";

export default {
    namespaced: true,

    state: {
        reservation: {},
    },

    getters: {
        reservation (state) {
            return state.reservation
        },
    },

    mutations: {
        SET_RESERVATION (state, value) {
            state.reservation = value
        },
    },

    actions: {
        async get ({ commit }, reservationId) {
            let reservation = await ReservationsRepository.get(reservationId)
            await commit('SET_RESERVATION', reservation.data);
            },
        refresh ({ dispatch, getters }) {
            dispatch('get', getters.reservation.id);
        }

    }
}
