function getTitle (vm) {
    const { title } = vm.$options
    if (title) {
        return typeof title === 'function'
            ? title.call(vm)
            : title
    }
}
export default {
    mounted () {
        const title = getTitle(this) ? getTitle(this) + ' | Petbooking Portal' :  'Petbooking Portal'
        if (title) {
            document.title = title
        }
    }
}