import PetsRepository from "@/api/PetsRepository";

export default {
    namespaced: true,

    state: {
        pet: {},
    },

    getters: {
        pet (state) {
            return state.pet
        },
    },

    mutations: {
        SET_PET (state, value) {
            state.pet = value
        },
    },

    actions: {
        async get ({ commit }, id) {
            let reservation = await PetsRepository.get(id)
            await commit('SET_PET', reservation.data);
            },
    }
}