<template>
  <div>
    <v-row>
      <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2" class="pb-0">
        <h1>Activity feed</h1>
      </v-col>
    </v-row>
    <v-row v-for="post in posts" :key="post.uuid">
      <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-0 px-sm-2 py-1 py-sm-2">
        <view-post :post="post" />
      </v-col>
    </v-row>
    <v-row v-if="posts.length === 0 && loading === false">
      <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2" class="pb-0">
        <h4>You do not have any activity posts yet. Check back again soon.</h4>
      </v-col>
    </v-row>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner"><v-progress-circular indeterminate color="primary"/></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import PostsRepository from "@/api/PostsRepository";
import InfiniteLoading from 'vue-infinite-loading';
import ViewPost from "@/components/ViewPost";


export default {
  name: 'UpdatesFeed',
  title: 'My Updates',
  components: {
    ViewPost,
    InfiniteLoading
  },
  data: () => ({
    page: 0,
    lastPage: 1,
    posts: [],
    loading: false,
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {
    infiniteHandler: async function (state) {
      this.loading = true;
      let response = await PostsRepository.index(this.page + 1)
      this.posts.push(...response.data)
      this.loading = false;
      this.page = response.meta.current_page;
      this.lastPage = response.meta.last_page;
      if (this.page === this.lastPage) {
        state.complete();
      }
      else {
        state.loaded();
      }
    },
  }
}
</script>

