export default {
  namespaced: true,

  state: {
    text: '',
    color: '',
    active: false,
  },

  getters: {
    text (state) {
      return state.text
    },
    color (state) {
      return state.color
    },
    active (state) {
      return state.active
    },
  },

  mutations: {
    SET_TEXT (state, value) {
      state.text = value
    },
    SET_COLOR (state, value) {
      state.color = value
    },
    SET_ACTIVE (state, value) {
      state.active = value
    }
  },

  actions: {
    trigger ({ commit }, data) {
      commit('SET_TEXT', data.text);
      commit('SET_ACTIVE', true);
      if("color" in data)
      {
        commit('SET_COLOR', data.color);
      }
    },
    close ({commit}) {
      commit('SET_ACTIVE', false);
      commit('SET_TEXT', '');
      commit('SET_COLOR', 'success');
    }
  }
}