import Vue from 'vue';
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0B4141',
                secondary: '#5F062F',
                accent: '#FF5C39',
                primaryLight: '#D2DED6',
            },
        },
    },
});

export default vuetify;
