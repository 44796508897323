import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import toast from './modules/toast'
import reservation from './modules/reservation';
import property from "@/store/modules/property";
import newCustomerOnboarding from "@/store/modules/newCustomerOnboarding";
import onboardingConfirmation from "@/store/modules/onboardingConfirmation";
import pet from "@/store/modules/pet";
import customer from "@/store/modules/customer";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    toast,
    reservation,
    property,
    newCustomerOnboarding,
    onboardingConfirmation,
    pet,
    customer
  }
})
