import ReservationsRepository from "@/api/ReservationsRepository";

export default {
    namespaced: true,

    state: {
        reservation: null,
        pet: null,
        tasks: null,
    },

    getters: {
        reservation (state) {
            return state.reservation;
        },
        pet (state) {
            return state.pet;
        },
        tasks (state) {
            return state.tasks;
        },
        tasksCompletedRate (state) {
            let values = Object.values(state.tasks);
            const n = values.length;
            let completedTasks = values.filter(function(item) {
                return item;
            })
            return Math.round(completedTasks.length / n * 100);
        }
    },

    mutations: {
        SET_RESERVATION (state, value) {
            state.reservation = value;
        },
        SET_PET (state, value) {
            state.pet = value;
        },
        SET_TASKS (state, value) {
            state.tasks = value;
        },
    },

    actions: {
        async getReservation ({ commit }, reservation_id) {
            let response = await ReservationsRepository.getOnboarding(reservation_id);
            commit('SET_RESERVATION', response.data.reservation);
            commit('SET_PET', response.data.pet);
            commit('SET_TASKS', response.data.tasks);
            },
    }
}
