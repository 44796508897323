import axios from 'axios';
import store from '../store';

export default class BaseAPIRepository {

    static resource() { return '' }

    static prefix(propertyId = null) {
        if(propertyId === null) {
            return '/api/user/';
        }
        else {
            return '/api/user/property/' + propertyId + '/';
        }

    }

    static formatIndexParams(options = null, search = null)    {
        let params = {};
        if(options !== null)
        {
            let sortPrefix = options.sortDesc[0] ? '-' : '';
            let sort = options.sortBy[0] == null ? '' : sortPrefix + options.sortBy[0];
            params = {
                params: {
                    page: options.page,
                    itemsPerPage: options.itemsPerPage ?? null,
                    sort: sort,
                    search: (search === null) ? '' : encodeURIComponent(search),
                }
            };
        }
        return params;
    }

    static index(options = null, search = null) {

        let params = this.formatIndexParams(options, search)

        return axios.get(this.prefix() + this.resource(), params).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static get(id) {
        return axios.get(this.prefix() + this.resource() +`/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static create(payload) {
        return axios.post(this.prefix() + this.resource(), payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static update(id, payload) {
        return axios.put(this.prefix() + this.resource() + `/${id}`, payload).then((response) => {
            store.dispatch('toast/trigger', {text: "The pet was successfully updated", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static delete(id) {
        return axios.delete(this.prefix()+ this.resource() + `/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static catchError(error = null)  {
        let errorMessage ="An error has occurred. Please try again";
        if(error !== null && error.response && error.response.status === 400) {
            errorMessage = error.response.data.message;
        }
        store.dispatch('toast/trigger', {text: errorMessage, color: "error"}, {root: true})
    }
}
