import axios from "axios";
import store from "@/store";

//TODO fix class inheritance - as cannot extend baseAPIrepository more than once in this project - as such copying all methods from baseAPIrepository
export default class PetsRepository	{

    static resource() { return 'pets' }

    static getBreeds(type) {
        return axios.get(this.prefix() + this.resource() +`/breeds`, {
            params: {
                type: type
            }
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static createDescription(id, payload) {
        return axios.post(this.prefix() + this.resource() + `/${id}/description`, payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static uploadVaccinations(propertyId, id, files, comment) {
        return axios.post(this.prefix(propertyId) + this.resource() + `/${id}/vaccinations/upload`, {
            attachments: files,
            comment: comment
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static uploadMedication(id, medication) {
        return axios.post(this.prefix() + this.resource() + `/${id}/medications`, {
            attachments: medication.attachments,
            comment: medication.comment,
            name: medication.name,
            description: medication.description,
            dose_details: medication.dose_details,
            refrigeration: medication.refrigeration ? 'Yes' : 'No',
            frequency: medication.frequency,
            time_of_day: medication.time_of_day,
        }).then((response) => {
            store.dispatch('toast/trigger', {text: "The medication was successfully uploaded", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getMedications(id, page) {
        return axios.get(this.prefix() + this.resource() +`/${id}/medications`, {
            params: {
                page: page
            }
        }).then((response) => {
            return response;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static deleteMedication(petId, id) {
        return axios.delete(this.prefix() + this.resource() + `/${petId}/medication/${id}`).then((response) => {
            store.dispatch('toast/trigger', {text: "The medication was successfully deleted", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getOwnFoodList(id, page) {
        return axios.get(this.prefix() + this.resource() +`/${id}/own-food`, {
            params: {
                page: page
            }
        }).then((response) => {
            return response;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static uploadOwnFood(id, ownFood) {
        return axios.post(this.prefix() + this.resource() + `/${id}/own-food`, ownFood).then((response) => {
            store.dispatch('toast/trigger', {text: "The own food was successfully uploaded", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static deleteOwnFood(petId, id) {
        return axios.delete(this.prefix() + this.resource() + `/${petId}/own-food/${id}`).then((response) => {
            store.dispatch('toast/trigger', {text: "The own food was successfully deleted", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static processUploadedProfilePhoto(id, file) {
        return axios.post(this.prefix() + this.resource() + `/${id}/profile-photo`, {
            temporaryFilename: file.temporaryFilename,
            type: file.type
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }


    //imported from base
    static prefix(propertyId = null) {
        if(propertyId === null) {
            return '/api/user/';
        }
        else {
            return '/api/user/property/' + propertyId + '/';
        }

    }

    static formatIndexParams(options = null, search = null)    {
        let params = {};
        if(options !== null)
        {
            let sortPrefix = options.sortDesc[0] ? '-' : '';
            let sort = options.sortBy[0] == null ? '' : sortPrefix + options.sortBy[0];
            params = {
                params: {
                    page: options.page,
                    itemsPerPage: options.itemsPerPage ?? null,
                    sort: sort,
                    search: (search === null) ? '' : encodeURIComponent(search),
                }
            };
        }
        return params;
    }

    static index(options = null, search = null) {

        let params = this.formatIndexParams(options, search)

        return axios.get(this.prefix() + this.resource(), params).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static get(id) {
        return axios.get(this.prefix() + this.resource() +`/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static create(payload) {
        return axios.post(this.prefix() + this.resource(), payload).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static update(id, payload) {
        return axios.put(this.prefix() + this.resource() + `/${id}`, payload).then((response) => {
            store.dispatch('toast/trigger', {text: "The pet was successfully updated", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static delete(id) {
        return axios.delete(this.prefix()+ this.resource() + `/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static catchError(error = null)  {
        let errorMessage ="An error has occurred. Please try again";
        if(error !== null && error.response && error.response.status === 400) {
            errorMessage = error.response.data.message;
        }
        store.dispatch('toast/trigger', {text: errorMessage, color: "error"}, {root: true})
    }

}
