import { extend } from 'vee-validate';
import { required, email, max_value, min_value, min } from 'vee-validate/dist/rules';
import { setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('email', email);
extend('required', required);
extend('max_value', max_value);
extend('min_value', min_value);
extend('min', min);

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend('isChecked', {
    getMessage(field) {
        return 'You must accept the ' + field;
    },
    validate(value) {
        return value === true;
    }
});