<template>
    <v-chip
        color="light"
        :small="small"
        :link="link"
        class="pl-0"
    >
        <v-avatar left color="secondary" class="ml-0">
            <span class="white--text">{{ initials }}</span>
        </v-avatar>
        {{ name }}
    </v-chip>
</template>

<script>

    export default {
        props: {
            name: String,
            small: {
                type: Boolean,
                default: true
            },
            link: {
                type: Boolean,
                default: true
            },

        },
        computed: {
            initials () {
                var names = this.name.split(' '),
                    initials = names[0].substring(0, 1).toUpperCase();

                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        },

    }
</script>
