<template>
	<chip 
		:name="pet.name">
	</chip>
</template>

<script>
import Chip from '../components/ChipComponent.vue'

export default {
	props: {
      pet: Object,
    },
  components: {
      Chip,
  },
}
</script>
