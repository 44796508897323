import BaseAPIRepository from './BaseAPIRepository.js'
import axios from "axios";

export default class PostsRepository extends BaseAPIRepository	{

    static resource() { return 'posts' }

    static index(page = 1) {

        return axios.get(this.prefix() + this.resource(), {
            params: {
                page: page
            }
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

}
