import axios from 'axios';
import store from "@/store";

export default class PackagesRepository{

	static resource() { return 'package-types' }
    
	static getAvailableTypes(propertyId) {
        return axios.get(this.prefix(propertyId) + this.resource()).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static purchasePackage(propertyId, packageId, payload ) {
        // eslint-disable-next-line no-unused-vars
        return axios.post(this.prefix(propertyId) + this.resource() + `/${packageId}/buy`, payload).then((response) => {
            store.dispatch('toast/trigger', {text: "You have successfully purchased the package", color: "success"}, {root: true});
            return true;
        }).catch((error) => {
            this.catchError(error);
            return false;
        })
    }

    static prefix(propertyId = null) {
        if(propertyId === null) {
            return '/api/user/';
        }
        else {
            return '/api/user/property/' + propertyId + '/';
        }
    }

    static catchError(error = null)  {
        let errorMessage ="An error has occurred. Please try again";
        if(error !== null && error.response && (error.response.status === 400 || error.response.status === 403)) {
            errorMessage = error.response.data.message;
        }
        store.dispatch('toast/trigger', {text: errorMessage, color: "error"}, {root: true})
    }

    static addCoupon(propertyId, packageId, code) {
        return axios.post(this.prefix(propertyId) + this.resource() + `/${packageId}/coupon`, {
            code: code,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getInvoice(invoiceId ) {
        // eslint-disable-next-line no-unused-vars
        return axios.get(this.prefix() + `invoice/${invoiceId}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }
    static payInvoice(invoiceId, payload ) {
        // eslint-disable-next-line no-unused-vars
        return axios.post(this.prefix() + `invoice/${invoiceId}/pay`, payload).then((response) => {
            store.dispatch('toast/trigger', {text: "You have successfully paid the invoice", color: "success"}, {root: true});
            return true;
        }).catch((error) => {
            this.catchError(error);
            return false;
        })
    }
    static addInvoiceCoupon(invoiceId, code) {
        return axios.post(this.prefix() + `invoice/${invoiceId}/coupon`, {
            code: code,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }
}