<template>
  <v-card
      :class="{'rounded-0': $vuetify.breakpoint.xs}"
      outlined
  >
    <v-container>
      <v-row class="my-0">
        <v-col class="py-0">
          <v-avatar
              color="secondary"
              class="white--text"
          >{{  post.property.name | toInitials }}</v-avatar><span class="subtitle-1 font-weight-medium ml-2">{{ post.property.name }}</span> <span class="caption">{{ post.published_at | toHumanReadableDate}}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-1">
          <text-view :text="post.message" :textLength="250" />
        </v-col>
      </v-row>
    </v-container>
    <v-responsive :aspect-ratio="16/9" v-if="post.media.length > 0">
      <v-carousel height="auto" hide-delimiters :show-arrows="post.media.length > 1">
        <v-carousel-item
            v-for="(media,i) in post.media" :key="i"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  class="v-btn--absolute theme--dark"
                  style="z-index: 100; right: 0"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  key="download"
                  link
                  @click="download(media)"
              >
                <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
                <v-list-item-title>Download</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-sheet color="black">
          <v-img
              v-if="media.type === 'image'"
              :aspect-ratio="16/9"
              :src="media.url"
              contain
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <video-player
              v-else-if="media.type === 'mux-video'"
              :options="getVideoOptions(media)"
          />
        </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-responsive>
  </v-card>
</template>

<script>

import VideoPlayer from "@/components/VideoPlayer";
import {mapGetters} from "vuex";
import TextView from "./ui/TextView.vue";


export default {
  props: {
    post: Object,
  },
  components: {
    VideoPlayer,
    TextView,
  },
  data: () => ({
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      customer: 'auth/customer'
    }),
  },
  methods: {
    getVideoOptions: function (media) {
      return {
        autoplay: false,
        controls: true,
        fluid: true,
        preload: 'metadata',
        poster: "https://image.mux.com/" + media.mux_video_playback_id + "/thumbnail.png?width=860",
        sources: [
          {
            src: "https://stream.mux.com/" + media.mux_video_playback_id + ".m3u8",
            type: "application/x-mpegURL"
          },
          {
            src: "https://stream.mux.com/" + media.mux_video_playback_id + "/medium.mp4",
            type: "video/mp4"
          }
        ],
        plugins: {
          mux: {
            debug: true,
            data: {
              env_key: process.env.VUE_APP_MUX_DATA_PUBLIC_KEY,
              // Metadata
              player_name: "Customer portal feed",
              video_id: media.mux_video_playback_id,
              viewer_user_id: this.customer.uuid,
              sub_property_id: this.post.property.name,
            }
          }
        }
      }
    },
    download: function(media) {
      if(media.type === 'image') {
        window.open(media.url)
      }
      else if(media.type === 'mux-video')
      {
        window.open(media.url + "?download=" +  media.mux_video_playback_id + ".mp4")
      }
    }
  }
}
</script>

