import BaseAPIRepository from './BaseAPIRepository.js'
import axios from 'axios';
import store from "../store";
export default class ReservationsRepository extends BaseAPIRepository	{

    static resource() { return 'reservations' }

    static getQuote(propertyId, date) {

        return axios.get(this.prefix(propertyId) + this.resource() + '/quote', {
            params: {
                date: date,
            }
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getQuoteFromRate(propertyId, rateId, date) {

        return axios.get(this.prefix(propertyId) + `rates/${rateId}/quote`, {
            params: {
                date: date,
            }
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getAvailability (propertyId, rateId, date_start, date_end, qty) {
        return axios.get(this.prefix() + 'property/' + propertyId + '/rates/' + rateId + '/availability', {
            params: {
                date_start: date_start,
                date_end: date_end,
                qty: qty,
            }
        })
            .then((response) => response.data)
            .catch(this.catchError)
    }

    static createReservations(propertyId, dates, rateId, petIds, recurringPayload = null, notes, discardUnavailableDates = false) {

        if(recurringPayload !== null && recurringPayload.frequency === 'single')    {
            recurringPayload = null;
        }

        return axios.post(this.prefix(propertyId) + this.resource(), {
            dates: typeof dates == 'string' ? [dates] : dates,
            rate_id: rateId,
            pet_ids: petIds,
            recurring: recurringPayload,
            notes: notes,
            discard_unavailable_dates: discardUnavailableDates,
        }).then((response) => {
            if (!response.data.success){
                return response.data;
            } else {
                store.dispatch('toast/trigger', {text: 'Reservation(s) successfully created', color: "success"}, {root: true});
            }
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static get(id) {
        return axios.get(`api/user/reservations/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static cancel(propertyId, reservationId, type = 'single') {
        return axios.post(this.prefix(propertyId) + this.resource() + `/${reservationId}/cancel`, {
            type: type
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static onboardingSubmit(payload) {
        return axios.post(this.prefix() + `onboarding/create`, payload
        ).then((response) => {
            return response;
        })
    }

    static getOnboarding(id) {
        return axios.get(this.prefix() + `onboarding/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }
}