import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import Vuetify from 'vuetify';


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios').default;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
window.axios.defaults.headers.common['Accept'] = 'application/json'
window.axios.defaults.withXSRFToken = true;

require('./assets/utils/validation.js');
require('./assets/utils/AxiosRequestInterceptor');

Vue.use(Vuetify);

Vue.use(VueTelInputVuetify, {
  Vuetify,
});

Vue.filter('toShortDate', function (value) {
  var moment = require('moment');
  return moment(value).format('ddd D MMM YY');
});

Vue.filter('toTime', function (value) {
  var moment = require('moment');
  return moment(value, 'hh,mm,ss').format('h:mm a');
});

Vue.filter('toInitials', function (value) {
  const values = value.split(' ');
  let initials = values[0].substring(0, 1).toUpperCase();
  if (values.length > 1) {
    initials += values[values.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
});

Vue.filter('toUCFirst', function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
})

Vue.filter('toDate', function (value) {
  var moment = require('moment');
  return moment(value).format('DD/MM/Y');
});

Vue.filter('toHumanReadableDate', function (value) {
  var moment = require('moment');
  return moment(value).fromNow();
});

import TitleMixin from './mixins/TitleMixin';
import VueTelInputVuetify from "vue-tel-input-vuetify";
Vue.mixin(TitleMixin);

//If the GA tracking ID is set in the environment variable init GA tracking
if('VUE_APP_GA_TRACKING_ID' in process.env) {
  Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GA_TRACKING_ID}
  }, router);
}

Vue.filter('toCurrency', function (value) {
  var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2
  });
  return formatter.format(value / 100);
});

Vue.filter('toInitials', function (value) {
  let names = value.split(' ');
  return names[0].substring(0, 1).toUpperCase() + ((names.length > 1) ? names[1].substring(0, 1).toUpperCase() : '');
});

Vue.config.productionTip = false

require('video.js/dist/video-js.css');

import "dropzone/dist/dropzone.css";



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
